import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ValidateCats from '../../../../components/common/ValidateCats';
// import { toast } from 'react-toastify';
// import { apiService } from '../../../../services/apiService';
function MemberConfirmation() {
    // const { requestId } = useParams();
    // const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
      const closeElement = () => {
        setIsVisible(true);
      };
  
  return (
    <div className='container-fluid px-0'>
        <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement}/>
        </div>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Case10000</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer">
                        <h3>Member Confirmation 
                            <select>
                                <option value="">In Progress</option>
                                <option value="">In Progress</option>
                                <option value="">In Progress</option>
                                <option value="">In Progress</option>
                            </select>
                        </h3>
                        <b>Jobseeker Name</b>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            List
                        </button>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                        <div className='knowledgetab_btnredirect'>
                            <button className="active_tabbtn">Overview</button>
                            <button>Assignments</button>
                            <button>Discussions</button>
                            <button>Knowledge</button>
                            <button>History</button>
                        </div>
                    </Grid>
                    <form className='w-100 mt-3' 
                        // onSubmit={handleSubmit}
                        >
                        {/* <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="mt-2">
                            <button type='submit' className='registersendbtn2 mb-0'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button>
                        </Grid> */}
                        
                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs i94validateinput">
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>How do you know the Jobseeker ?<small>*</small></span>
                                <select name="gender" 
                                // value={formData.gender}
                                // onChange={handleInputChange} 
                                >
                                    <option value="">2nd degree friend (Friend of a Friend)</option>
                                    <option value="1">2nd degree friend (Friend of a Friend)</option>
                                    <option value="">Type 2</option>
                                    <option value="">Type 3</option>
                                </select>
                                {/* {errors.first_name && <p style={{color:"Red"}}>{errors.first_name[0]}</p>} */}
                            </Grid>
                            <Grid size={{sm:12}} container className="mt-3" flexDirection="column">
                                <span>I agree that my relationship with Tekpossible may be affected if a dispute arises between the Referee and Tekpossible?<small>*</small></span>
                                <div className='d-flex align-items-center main_radioregister'>
                                    <input
                                        type="radio" 
                                        name="laws_and_regulations"
                                        // checked={formData.laws_and_regulations} 
                                        // onChange={handleInputChange} 
                                    />Yes
                                    <input
                                        type="radio" 
                                        name="laws_and_regulations"
                                        // checked={formData.laws_and_regulations} 
                                        // onChange={handleInputChange} 
                                    />No
                                </div>
                                {/* {errors.laws_and_regulations && <p style={{color:"Red"}}>{errors.laws_and_regulations}</p>} */}
                            </Grid>
                            <Grid size={{sm:12}} container className="mt-3" flexDirection="column">
                                <span>I understand that Tekpossible may take legal action against the Referee for any disputes arising from the Pay After Placements agreement.<small>*</small></span>
                                <div className='d-flex align-items-center main_radioregister'>
                                    <input
                                        type="radio" 
                                        name="laws_and_regulations"
                                        // checked={formData.laws_and_regulations} 
                                        // onChange={handleInputChange} 
                                    />Yes
                                    <input
                                        type="radio" 
                                        name="laws_and_regulations"
                                        // checked={formData.laws_and_regulations} 
                                        // onChange={handleInputChange} 
                                    />No
                                </div>
                                {/* {errors.laws_and_regulations && <p style={{color:"Red"}}>{errors.laws_and_regulations}</p>} */}
                            </Grid>                           
                            <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                <button type='submit' className='registersendbtn2'>Save</button>
                                {/* <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button> */}
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default MemberConfirmation
